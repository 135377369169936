<script>
  import { _, locale } from "svelte-i18n";
  import { querystring, push } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import Reservations from "../../reservations-service";
  import ModalLogin from "../../user-validation/modal-login/component.svelte";
  import { format } from "date-fns";
  import ValidationUser from "../../authentication-service";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let languagesEnabled = [];
  let language;
  let faeturesList = [];
  let managementColors = {};
  let showBtnBack = true;
  let propEmpty = "";
  let reload = true;
  let dataHeader = JSON.parse(localStorage.getItem("dataHeader"));
  let outlet;
  let fileUrl = undefined;
  let contentUrl = undefined;
  let showContent = false;
  let openModalOffMenu = false;
  let disabledButton = false;
  let loadingButton = false;
  let disabledButtonTerms = false;
  let loadingButtonTerms = false;
  let datalogin = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : null;
  let modalLoginActive = null;
  let showTermsAndConditions = false;
  let modalOpen = null;
  let termsAccepted = [];
  let showAlert = false;
  let showModalAud = false;
  let phoneNumber = "";

  let MyObjectParams = $querystring
    ? $querystring.split("&").reduce((r, c) => {
        r[c.split("=")[0]] = c.split("=")[1];
        return r;
      }, {})
    : {};
  let thisOutletNotAuthorized = false;

  let dictionaryColors = [
    {
      title: "redfilter",
      color: "#FD0D1B",
    },
    {
      title: "orangefilter",
      color: "#FD9726",
    },
    {
      title: "yellowfilter",
      color: "#FFCC21",
    },
    {
      title: "greenfilter",
      color: "#6AA84E",
    },
    {
      title: "bluefilter",
      color: "#0A23FB",
    },
    {
      title: "violetfilter",
      color: "#9825FB",
    },
    {
      title: "purplefilter",
      color: "#FD28FC",
    },
    {
      title: "greyfilter",
      color: "#969696",
    },
    {
      title: "redwinefilter",
      color: "#A51C0E",
    },
    {
      title: "mustardfilter",
      color: "#F4B92A",
    },
    {
      title: "gcreamfilter",
      color: "#1AB394",
    },
    {
      title: "blightfilter",
      color: "#3C74D6",
    },
    {
      title: "darkvioletfilter",
      color: "#674EA6",
    },
    {
      title: "dpurplefilter",
      color: "#A74F79",
    },
    {
      title: "gdarkfilter",
      color: "#404040",
    },
    {
      title: "cdarkfilter",
      color: "#5B0F04",
    },
    {
      title: "goldfilter",
      color: "#E69239",
    },
    {
      title: "clightfilter",
      color: "#B25D16",
    },
    {
      title: "ydarkfilter",
      color: "#BF8F1F",
    },
    {
      title: "greendarkfilter",
      color: "#264F13",
    },
    {
      title: "bluetwofilter",
      color: "#1B4682",
    },
    {
      title: "bluedarkfilter",
      color: "#1F124D",
    },
    {
      title: "purpledarkfilter",
      color: "#4B102F",
    },
    {
      title: "blackcolor",
      color: "#000000",
    },
    {
      title: "ligthredfilter",
      color: "#EF9A9A",
    },
    {
      title: "ligthorangefilter",
      color: "#FFCC80",
    },
    {
      title: "ligthyellowfilter",
      color: "#FDF5CC",
    },
    {
      title: "ligthgreenfilter",
      color: "#C5E1A5",
    },
    {
      title: "ligthbluefilter",
      color: "#C7DAFA",
    },
    {
      title: "ligthvioletfilter",
      color: "#E2D9FF",
    },
    {
      title: "ligthpurplefilter",
      color: "#E8D2DC",
    },
    {
      title: "ligthgreyfilter",
      color: "#E3E3E3",
    },
    {
      title: "ligthredwinefilter",
      color: "#E06767",
    },
    {
      title: "ligthmustardfilter",
      color: "#F8B36B",
    },
    {
      title: "ligthgcreamfilter",
      color: "#FED865",
    },
    {
      title: "ligthblightfilter",
      color: "#6AA84E",
    },
    {
      title: "ligthdarkvioletfilter",
      color: "#A3C2F4",
    },
    {
      title: "ligthdpurplefilter",
      color: "#C4B1F7",
    },
    {
      title: "ligthgdarkfilter",
      color: "#FFA5D0",
    },
    {
      title: "ligthcdarkfilter",
      color: "#969696",
    },
    {
      title: "ligthgoldfilter",
      color: "#E42527",
    },
    {
      title: "ligthclightfilter",
      color: "#FF8600",
    },
    {
      title: "ligthydarkfilter",
      color: "#FFC50F",
    },
    {
      title: "ligthgreendarkfilter",
      color: "#1BB394",
    },
    {
      title: "ligthbluetwofilter",
      color: "#589AFF",
    },
    {
      title: "ligthbluedarkfilter",
      color: "#B45FFF",
    },
    {
      title: "ligthpurpledarkfilter",
      color: "#FF4EA3",
    },
    {
      title: "ligthblackcolor",
      color: "#BF8F1F",
    },
  ];

  const getColorComponentsAndIcons = (color) => {
    return dictionaryColors.find((item) => item.color == color);
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors"),
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColor();

  const init = (lang, entryPoint) => {
    reload = true;
    showTermsAndConditions = false;
    loadingButton = false;
    showModalAud = false;
    showAlert = false;
    modalOpen = null;
    modalLoginActive = null;
    thisOutletNotAuthorized = false;
    setTimeout(() => {
      const code = window.location.href.includes("?code=")
        ? window.location.href.split("code=")[1].includes("&")
          ? window.location.href.split("code=")[1].split("&")[0]
          : window.location.href.split("code=")[1]
        : null;
      (code
        ? ValidationUser.loginMagicLink(state.propertyId, { code })
        : ValidationUser.isLogIn(state.propertyId).catch(() => true)
      ).then(() => {
        datalogin = localStorage.getItem("auth")
          ? JSON.parse(localStorage.getItem("auth"))
          : null;

        Promise.all([
          PropertyFeatures.list({ propertyId: state.propertyId }, lang),
          PropertyFeatures.guestAppSettings({
            propertyId: state.propertyId,
          }),
          PropertyFeatures.proppertyInformation({
            propertyId: state.propertyId,
          }),
          PropertyFeatures.outletDetail({
            propertyId: state.propertyId,
            outleType: "promotion",
            language: lang,
            id: state.idOutlet,
            reservationId:
              datalogin && datalogin.reservationId
                ? datalogin.reservationId
                : undefined,
          }),
        ]).then(
          ([features, guestappsettins, propertyInformation, outletDetail]) => {
            outlet = Object.assign({}, outletDetail.data, {
              description: outletDetail.data.description
                ? outletDetail.data.description.replace(/\n/g, "<br />")
                : "",
            });
            if (outletDetail.action == "continue") {
              if (MyObjectParams.messageId) {
                PropertyFeatures.markOpenMessageBy(state.propertyId, {
                  outletId: state.idOutlet,
                  channel: MyObjectParams.channel
                    ? MyObjectParams.channel
                    : "GUESTAPP",
                  messageId: MyObjectParams.messageId
                    ? MyObjectParams.messageId
                    : null,
                });
              }

              if (!outlet.inService) {
                disabledButton = true;
              } else {
                disabledButton = false;
              }
              if (termsAccepted.length === outlet.terms.length) {
                outlet.terms = outlet.terms.map((t) => t.id);
              }
            } else if (outletDetail.action == "require-login") {
              modalLoginActive = "view";
            } else {
              thisOutletNotAuthorized = true;
            }

            propertyData = propertyInformation;
            (phoneNumber = propertyInformation.contactGuestInformation.find(
              (p) => p.contenType == "phone",
            )
              ? propertyInformation.contactGuestInformation
                  .filter((p) => p.contenType == "phone")
                  .map((e) => e.title)
                  .join(", ")
              : ""),
              (dataHeader = Object.assign({}, dataHeader, {
                nameFeature: outlet ? outlet.name : null,
                imgFeature: outlet ? outlet.cover : null,
              }));
            localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
            localStorage.setItem("lastRoute", '"viewpromotionslist"');
            faeturesList = features.filter(
              (fet) => fet.enabled || fet.codename == "property.details",
            );
            validationLanguage(
              guestappsettins.languages,
              propertyInformation.language.code,
            );
            validationColors(guestappsettins);
            setFontFamily(guestappsettins.fontFamily);
            reload = false;
          },
        );
      });
    }, 200);
  };

  const validationColors = (guestappsettins) => {
    managementColors = {
      filter: guestappsettins.filter,
      color: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .color,
      title: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .title,
      general: Reservations.getColorsContrast(guestappsettins),
    };

    localStorage.setItem("managementColors", JSON.stringify(managementColors));
  };

  $: if (params) {
    state = Object.assign({ features: [], reservation: undefined }, params);
    init(JSON.parse(localStorage.getItem("language")), false);
  }
  if (dataHeader && !dataHeader.linkLogin) {
    init(JSON.parse(localStorage.getItem("language")), false);
  } else {
    init(
      JSON.parse(localStorage.getItem("language")),
      JSON.parse(localStorage.getItem("entryPoint")),
    );
  }

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let handleLanguage = (e) => {
    init(e, JSON.parse(localStorage.getItem("entryPoint")));
  };

  const clickOnRequest = () => {
    disabledButton = true;
    loadingButton = true;

    PropertyFeatures.validateRequestThePromotion(state.propertyId, {
      outletId: state.idOutlet,
      reservationId:
        datalogin && datalogin.reservationId
          ? datalogin.reservationId
          : undefined,
    })
      .then((response) => {
        if (response.action == "require-login") {
          disabledButton = false;
          modalLoginActive = "request";
        } else if (response.action == "continue") {
          disabledButton = false;
          goToNext();
        } else {
          showModalAud = true;
          disabledButton = true;
        }
        loadingButton = false;
      })
      .catch((err) => {
        console.error(err);
        alert("Hubo un error");
        disabledButton = false;
        loadingButton = false;
      });
  };

  const actionBeforeLogin = (event) => {
    if (event.detail.type == "close") {
      if (modalLoginActive == "view") {
        push(`/properties/${state.propertyId}`);
      } else {
        modalLoginActive = null;
      }
    } else if (event.detail.type == "ok") {
      if (modalLoginActive == "request") {
        modalLoginActive = null;
        datalogin = localStorage.getItem("auth")
          ? JSON.parse(localStorage.getItem("auth"))
          : null;
        goToNext();
      } else {
        window.location.reload(true);
      }
    }
  };

  const goToNext = () => {
    if (outlet.terms && outlet.terms.length > 0) {
      showTermsAndConditions = true;
    } else {
      requestPromotion();
    }
  };

  const requestPromotion = () => {
    if (disabledButtonTerms) {
      return;
    }
    disabledButtonTerms = true;
    loadingButtonTerms = true;

    if (outlet.actionType == "external-link") {
      window.open(outlet.actionData.link, "_blank");
      showTermsAndConditions = false;
      loadingButtonTerms = false;
    } else {
      PropertyFeatures.createNewRequestThePromotion(state.propertyId, {
        outletId: state.idOutlet,
        reservationId: datalogin ? datalogin.reservationId : null,
        reservationVip: datalogin ? datalogin.reservationVip : null,
        browserIdentify:
          datalogin && datalogin.reservationId
            ? null
            : localStorage.getItem("browser-identify"),
        serviceId: localStorage.getItem("serviceCreate")
          ? JSON.parse(localStorage.getItem("serviceCreate")).featureId
          : null,
        guestName:
          datalogin && datalogin.guestName ? datalogin.guestName : null,
        roomNumber:
          datalogin && datalogin.roomNumber ? datalogin.roomNumber : null,
        channel: MyObjectParams.channel ? MyObjectParams.channel : "GUESTAPP",
        messageId: MyObjectParams.messageId ? MyObjectParams.messageId : null,
      })
        .then((response) => {
          showTermsAndConditions = false;
          if (response.action == "not-authorized") {
            showModalAud = true;
            disabledButtonTerms = true;
          } else if (response.action == "created") {
            push(
              `/properties/${state.propertyId}/my-experience/${response.data.id}`,
            );
            disabledButtonTerms = true;
          } else {
            // Bajo cualquier tipo de error
          }
          loadingButtonTerms = false;
        })
        .catch((err) => {
          console.error(err);
          disabledButtonTerms = false;
          loadingButtonTerms = false;
        });
    }
  };

  const goToList = () => {
    push(`/properties/${state.propertyId}`);
  };
</script>

<!-- svelte-ignore non-top-level-reactive-declaration -->
<!-- svelte-ignore non-top-level-reactive-declaration -->
<div
  use:styling={{
    background:
      managementColors &&
      managementColors.general &&
      managementColors.general.backgroundColor,
  }}
  class="container-fluid row"
>
  {#if modalLoginActive}
    <ModalLogin {state} {managementColors} on:action={actionBeforeLogin}
    ></ModalLogin>
  {:else if modalOpen}
    <div
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class="col s12 m12 l12 xl12 outlets"
    >
      <div class="col s12">
        <div class="div__circle">
          <a
            type="button"
            class="button__circle"
            href="#!"
            on:click|preventDefault={() => {
              modalOpen = null;
            }}
          >
            <i class="material-icons icon__back">keyboard_arrow_left</i>
          </a>
        </div>

        <div class="div__terms__cond">
          <h3 class="txt__body">
            {modalOpen.title}
          </h3>

          <span class="txt__body__desc">{@html modalOpen.body}</span>
        </div>
      </div>
    </div>
  {:else if showTermsAndConditions}
    <div
      class="header__terms"
      use:styling={{ background: managementColors.general.colorAlternative }}
    >
      <div class="div__circle__terms">
        <a
          type="button"
          class="button__circle"
          href="#!"
          on:click|preventDefault={() => {
            showTermsAndConditions = null;
          }}
        >
          <i class="material-icons icon__back">keyboard_arrow_left</i>
        </a>
      </div>
      <div class="img__and__span">
        <div class="img__header__term">
          <img src={outlet.cover} class="img__terms" />
        </div>
        <span
          class="span__title"
          use:styling={{
            color: managementColors.general.colorLetter,
          }}>{outlet.name}</span
        >
      </div>
    </div>

    <div
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class="term__and__cond"
    >
      <div class="col-s12">
        <div class="div__terms__cond">
          <div>
            <h3 class="txt__body">{$_("Terms and Conditions")}</h3>
          </div>
          <div class="cont__terms">
            {#each outlet.terms as term}
              <div>
                <p>
                  <label
                    style="display: inline-block;"
                    for="acceptTermsCondition{term.id}"
                  >
                    <input
                      type="checkbox"
                      id="acceptTermsCondition{term.id}"
                      bind:group={termsAccepted}
                      class="filled-in"
                      value={term.title}
                    />
                    <span
                      class="acc__terms"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                      >{term.title.includes("Políticas")
                        ? $_("I accept the F")
                        : $_("I accept the M")}</span
                    >
                  </label>
                  <a
                    class="color__terms"
                    href="#{term.id}"
                    target="_blank"
                    use:styling={{
                      color: managementColors.color,
                    }}
                    on:click|preventDefault={() => {
                      modalOpen = term;
                    }}>{term.title}</a
                  >
                </p>
              </div>
            {/each}
          </div>
        </div>

        {#if termsAccepted.length != outlet.terms.length && showAlert}
          <div class="col-12">
            <div class="alert-condition">
              <i class="material-icons">warning</i>
              <span class="txt__warning" style="vertical-align: super;"
                >{$_("To continue you must accept the terms and conditions")}
              </span>
            </div>
          </div>
        {/if}
      </div>
    </div>
    <div
      class="col s12 footer__promotion {managementColors.general.colorMode ==
      'dark'
        ? 'border--dark'
        : managementColors.general.colorMode == 'white'
          ? 'border--white'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
    >
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="col s12 div__button"
        on:click|preventDefault={() =>
          (showAlert = termsAccepted.length !== outlet.terms.length)}
      >
        <button
          class="btn btn-success buttom__footer"
          use:styling={{
            color: managementColors.general.colorAlternative,
            background: managementColors.color,
          }}
          disabled={termsAccepted.length != outlet.terms.length ||
            disabledButtonTerms}
          on:click|preventDefault={requestPromotion}
        >
          {#if loadingButton}
            {$_("Loading")}
          {:else}
            {$_("Accept")}
          {/if}
        </button>
      </div>
    </div>
  {:else}
    {#if !reload}
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'dark'
          ? 'navifation-cont-border--dark'
          : managementColors.general.colorMode == 'white'
            ? 'navifation-cont-border--white'
            : ''}"
        use:styling={{
          background: managementColors.general.backgroundColor,
        }}
      >
        <NavigationTop
          bind:languageConection={handleLanguage}
          bind:logoUrl={propertyData.logo}
          bind:propertyName={propertyData.name}
          bind:propertyId={state.propertyId}
          bind:lang={language}
          bind:listLanguagesEnabled={languagesEnabled}
          bind:features={faeturesList}
          bind:backgroundColorView={managementColors.general.backgroundColor}
          bind:colorLetter={managementColors.general.colorLetter}
          bind:colorAlternative={managementColors.general.colorAlternative}
          bind:managementColors
          bind:showBtnBack
          bind:iframeFromLanding={propEmpty}
        />
      </div>

      {#if thisOutletNotAuthorized}
        <div
          use:styling={{
            background: managementColors.general.backgroundColor,
          }}
          class="col s12 m12 l12 xl12 outlets"
        >
          <div class="text-center">
            <div
              class="col s12 div__status__cancel"
              style="margin-bottom: 3rem;"
            >
              <div
                class="circle__status__cancel {managementColors.general
                  .colorMode == 'white'
                  ? 'circle__status__cancel--white'
                  : managementColors.general.colorMode == 'dark'
                    ? 'circle__status__cancel--dark'
                    : ''}"
              >
                <i class="material-icons icon--status--cancel"> cancel</i>
              </div>
            </div>
            <h2
              style="font-size: 3rem; margin-top:2rem;font-weight: 700 !important;"
              class="status__request__title__text {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
            >
              {$_("Sorry for the inconvenience")}
            </h2>
            <p
              style="font-size: 1rem;"
              class="status__request__title__text {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
            >
              {$_(
                "This promotion is not available for your type of reservation, we invite you to visit the list of promotions.",
              )}
            </p>
            <div
              class="col s12 {managementColors.general.colorMode == 'white'
                ? 'footer-border--white'
                : managementColors.general.colorMode == 'dark'
                  ? 'footer-border--dark'
                  : ''}"
              use:styling={{
                background: managementColors.general.backgroundColor,
              }}
            >
              <button
                class="btn-success button--exit {managementColors.general
                  .colorMode}2"
                on:click|preventDefault={goToList}
              >
                <span
                  class="text--exit {$locale} {managementColors.general
                    .colorMode}2"
                >
                  {$_("Go to home")}
                </span>
              </button>
            </div>
          </div>
          <!-- <button class="dark-btn" on:click|preventDefault={goToList}>
            Ir al listado
          </button> -->
        </div>
      {:else}
        <div
          use:styling={{
            background: managementColors.general.backgroundColor,
          }}
          class="col s12 m12 l12 xl12 outlets"
        >
          <div
            class={!outlet.inService && outlet.type != "other"
              ? "col s12 preview__img off__content"
              : "col s12 preview__img"}
          >
            {#if outlet.coverIcon}
              <!-- svelte-ignore a11y-missing-attribute -->
              <img
                src={outlet.cover}
                class="preview__img__photo {getColorComponentsAndIcons(
                  managementColors.color,
                ).title}"
                style="width: 200px; text-align: center; "
              />
            {:else}
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src={outlet.cover} class="preview__img__photo" />
            {/if}
          </div>

          <div class="col s12 preview__name">
            <span
              class="preview__name__text line__elipsis"
              use:styling={{
                color:
                  !outlet.inService && outlet.type != "other"
                    ? managementColors.general.colorOff
                    : managementColors.general.colorLetter,
              }}
            >
              {outlet.name}
            </span>
          </div>

          <div class="col s12 preview__description chip__div">
            {#if !outlet.inService}
              <span
                class="preview__description__text not__avai"
                use:styling={{
                  color: managementColors.general.colorOff,
                }}
              >
                <span
                  class="off-Oservice"
                  use:styling={{
                    color: managementColors.general.colorOff,
                  }}>{$_("NOT AVAILABLE")}</span
                >
              </span>
              <span
                class="preview__description__text not__avai fix__short"
                use:styling={{
                  color: managementColors.general.colorOff,
                }}
              >
                <i
                  class="material-icons icon__hour {managementColors.general
                    .colorMode == 'dark'
                    ? 'icon__hour__dark'
                    : managementColors.general.colorMode == 'white'
                      ? 'icon__hour__white'
                      : ''}">schedule</i
                >
                {#if outlet.timeLeft.in == "day" && outlet.timeLeft.value === 1}
                  {$_("Available tomorrow at")}
                  {outlet.nextAvailabilities.fromHours} -
                  {outlet.nextAvailabilities.toHours}
                {:else}
                  {$_("Available in")}
                  {outlet.timeLeft.value}
                  {$_(outlet.timeLeft.in)}
                {/if}
              </span>
            {/if}
            {#if outlet.inService}
              <span
                class="preview__description__text"
                use:styling={{
                  color: managementColors.general.colorLetter,
                }}
                >{outlet.currentAvailabilities.fromHours}-{outlet
                  .currentAvailabilities.toHours}</span
              >
            {/if}
          </div>

          <div class="col s12 txt__description">
            <span
              class="preview__description__text"
              use:styling={{
                color:
                  !outlet.inService && outlet.type != "other"
                    ? managementColors.general.colorOff
                    : managementColors.general.colorLetter,
              }}
            >
              {@html outlet.description}
            </span>
          </div>

          <div class="col s12 txt__description div__bottom">
            <span
              class="preview__description__text txt__price"
              use:styling={{
                color:
                  !outlet.inService && outlet.type != "other"
                    ? managementColors.general.colorOff
                    : managementColors.general.colorLetter,
              }}
            >
              {outlet.worthTypeCurrency}
              {outlet.worthPrice}
            </span>
          </div>
        </div>
        <div
          class="col s12 footer__promotion {managementColors.general
            .colorMode == 'dark'
            ? 'border--dark'
            : managementColors.general.colorMode == 'white'
              ? 'border--white'
              : ''}"
          use:styling={{
            background: managementColors.general.backgroundColor,
          }}
        >
          {#if showModalAud}
            <div class="col-12">
              <div class="alert-condition">
                <i class="material-icons">warning</i>
                <span class="txt__warning" style="vertical-align: super;"
                  >{$_("To access this promotion contact the property")}
                  {phoneNumber}
                </span>
              </div>
            </div>
          {/if}
          <div class="col s12 div__button">
            <span
              class="txt__footer"
              use:styling={{
                color: managementColors.general.colorLetter,
              }}
            >
              {$_("Promotion valid from the")}
              {$_(format(new Date(outlet.startDate), "dd"))}
              {$_("of")}
              {$_(format(new Date(outlet.startDate), "MMM"))}
              {$_(format(new Date(outlet.startDate), "yyyy"))}
              {#if outlet.endDate}
                {$_("to the")}
                {$_(format(new Date(outlet.endDate), "dd"))}
                {$_("of")}
                {$_(format(new Date(outlet.endDate), "MMM"))}
                {$_(format(new Date(outlet.endDate), "yyyy"))}
              {/if}
            </span>
            {#if outlet.actionableStatus}
              <button
                class="btn btn-success buttom__footer"
                use:styling={{
                  color: managementColors.general.colorAlternative,
                  background: managementColors.color,
                }}
                disabled={disabledButton}
                on:click|preventDefault={clickOnRequest}
              >
                {#if loadingButton}
                  {$_("Loading")}
                {:else}
                  {outlet.actionTitle}
                {/if}
              </button>
            {/if}
          </div>
        </div>
      {/if}
    {/if}

    {#if reload}
      <div
        use:styling={{
          background:
            managementColors &&
            managementColors.general &&
            managementColors.general.backgroundColor,
        }}
        class="reloading row col s12"
      >
        <Reloading bind:managementColors />
      </div>
    {/if}
  {/if}
</div>

<style>
  .button--exit {
    width: 17rem;
    height: 38px;
    margin-top: 1rem;
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    opacity: 1;
    cursor: pointer;
  }
  .button--exit.white2 {
    background: #212121 0% 0% no-repeat padding-box;
  }
  .button--exit.dark2 {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  .button--exit:hover {
    box-shadow: 0px 4px 8px #3636368a;
  }

  .reloading {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .circle__status__cancel--white {
    background: #ffdadd 0% 0% no-repeat padding-box;
  }
  .circle__status__cancel--dark {
    background: rgba(255, 72, 85, 0.3);
  }

  .circle__status__cancel {
    width: 142px;
    height: 142px;
  }

  .circle__status__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 1;
  }
  .icon--status--cancel {
    font-size: 104px;
  }
  .icon--status--cancel {
    font-size: 100px;
    letter-spacing: 0px;
    color: #ff4855;
    text-transform: lowercase;
    opacity: 1;
  }

  .div__status__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon__hour {
    position: relative;
    opacity: 0.5;
    margin-right: 4px;
    margin-left: 4px;
    font-size: 17px;
    top: 0px;
  }

  .button__circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--buttonCircle);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button__circle:hover {
    text-decoration: none;
  }

  .div__circle {
    position: absolute;
    top: 10px;
    left: 12px;
  }

  .div__circle__terms {
    float: left;
    height: 56px;
    margin-top: 12px;
    margin-left: 11px;
  }

  .img__terms {
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
  }

  .txt__body {
    color: var(--colorLetter);
    font-weight: 700;
    font-size: 2.5rem !important;
  }

  .txt__body__desc {
    font-size: 1rem;
    color: var(--colorLetter);
  }

  .txt__warning {
    color: var(--colorLetter);
    font-size: 0.875rem;
  }

  .icon__back {
    color: var(--colorLetter) !important;
  }

  .padding0 {
    padding: 0px !important;
  }

  .icon__hour__dark {
    color: #f4f4f4;
  }
  .icon__hour__white {
    color: #686c6d;
  }
  .txt__price {
    font-weight: 700;
  }

  .container-fluid {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .view-description__cont-image {
    width: 100%;
  }

  .preview__img {
    position: relative;
    display: flex;
    justify-content: center;
    /*height: 370px;*/
    padding: 0px !important;
  }

  .preview__img__photo {
    height: 100%;
    width: 100%;
  }

  .preview__img__logo {
    position: absolute;
    bottom: -34px;
    width: 20%;
    height: 35%;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
  }

  .filter__img {
    filter: invert(55%) sepia(24%) saturate(6502%) hue-rotate(133deg)
      brightness(100%) contrast(80%);
  }

  .preview__name {
    padding: 48px 0px 0px 0px !important;
    word-break: break-word;
    text-align: left;
  }

  .preview__name__text {
    color: #212121;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .preview__description {
    word-break: break-word;
    padding: 0px !important;
    text-align: left;
  }

  .preview__description__text {
    color: #212121;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .not__avai {
    display: inline-flex;
    align-items: center;
  }

  .preview__list {
    padding: 10px 0px 0px 0px !important;
    display: grid;
  }

  .preview__list__item {
    display: grid;
    height: 52px;
    margin-bottom: 8px;
    box-shadow: 0px 4px 8px #00000014;
    border-radius: 4px;
    cursor: pointer;
  }

  .preview__list__item__img {
    width: 52px;
    height: 52px;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
    display: inline-block;
    justify-self: end;
    position: relative;
    top: -52px;
  }

  .preview__list__item__content {
    font-weight: 600;
    height: 52px;
    flex-grow: 100;
    opacity: 1;
    display: inline-block;
    width: 84%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    padding: 15px 0px 0px 15px !important;
  }

  .img__header__term {
    margin-right: 10px;
    border-radius: 4px;
    margin-left: 16px;
    width: 40px;
    height: 40px;
  }

  .header__terms {
    border-bottom: 1px solid var(--buttonCircle);
    height: 61px;
    width: 100% !important;
    position: fixed;
    z-index: 1000;
  }

  .span__title {
    margin-top: 5px;
    font-size: 0.875rem;
    font-weight: 700;
    display: inline-block;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .img__and__span {
    display: flex;
    margin-top: 15px;
    justify-content: center;
  }

  .preview__list__item__img__photo {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 0px 4px 4px 0px;
  }

  .outlets {
    padding: 0px 230px 0px 230px !important;
    top: 82px;
    text-align: center;
  }

  .txt__description {
    word-break: break-word;
    padding: 8px 0px 0px 0px !important;
    text-align: left;
  }

  .footer__promotion {
    padding: 5px 0px 32px 0px !important;
    position: fixed;
    bottom: 0px;
    background: #212121;
    text-align: center;
  }

  .buttom__footer {
    width: 94%;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    margin-top: 10px;
    text-transform: capitalize;
  }

  .txt__footer {
    font-size: 10px;
    font-weight: 700;
  }

  .border--dark {
    border-top: 1px solid #363636;
  }
  .border--white {
    border-top: 1px solid #e3e3e3;
  }

  .div__button {
    padding: 0px !important;
  }

  .acc__terms {
    line-height: 20px;
  }

  [type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
    background-color: transparent;
    border: 2px solid var(--colorLetter);
  }

  [type="checkbox"].filled-in:checked + span:not(.lever):after {
    border: 2px solid var(--dynamic-color);
    background-color: var(--dynamic-color);
  }

  @media only screen and (min-width: 20px) {
    .outlets {
      padding: 100px 0px 0px 0px !important;
      /* padding: 0px 0px 1040px 0px !important; */
      text-align: center;
    }

    .preview__list__item {
      width: 100% !important;
    }
    .preview__img {
      /*height: 300px;*/
      margin-top: -40px;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(1, 1fr);
    }

    .preview__description__text {
      font-size: 14px;
    }
    .preview__name__text {
      font-size: 20px;
    }

    .preview__list__item__content {
      font-size: 14px;
    }
    .preview__img__logo {
      width: 102px;
      height: 102px;
    }

    .preview__img__photo {
      border-radius: none !important;
    }

    .preview__list {
      padding: 10px 12px 0px 12px !important;
    }

    .preview__name {
      padding: 8px 12px 0px 12px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 12px 0px 12px !important;
    }

    .preview__description {
      padding: 0px 12px 0px 12px !important;
    }
  }

  @media only screen and (min-width: 700px) {
    .outlets {
      padding: 95px 32px 315px 32px !important;
      text-align: center;
    }

    .preview__img {
      /*height: 370px;*/
      margin-top: 0;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }

    .preview__description__text {
      font-size: 16px;
    }
    .preview__name__text {
      font-size: 24px;
    }

    .preview__list__item__content {
      font-size: 14px;
    }
    .preview__img__logo {
      width: 148px;
      height: 148px;
    }

    .preview__list {
      padding: 10px 0px 0px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 5px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }
  }
  @media only screen and (min-width: 1200px) {
    .outlets {
      padding: 95px 230px 950px 230px !important;
      text-align: center;
    }

    .preview__img {
      /*height: 400px;*/
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .preview__img__logo {
      width: 190px;
      height: 190px;
    }

    .div__button {
      padding: 0px !important;
      width: 34% !important;
      float: right !important;
    }
  }

  @media only screen and (min-width: 1420px) {
    .outlets {
      padding: 95px 320px 950px 320px !important;
      text-align: center;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1550px) {
    .outlets {
      padding: 95px 350px 950px 350px !important;
      text-align: center;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__img {
      /*height: 450px;*/
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }

    .preview__img__logo {
      width: 204px;
      height: 204px;
    }
  }

  @media only screen and (min-width: 1800px) {
    .outlets {
      padding: 200px 450px 200px 450px !important;
      /* padding: 142px 450px 1000px 450px !important; */
      text-align: center;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }
  }

  .off-Oservice {
    background: #e3e3e3;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    color: #212121 !important;
  }

  .off__content {
    opacity: 24%;
  }

  .off__content__txt {
    color: #686c6d !important;
  }

  .off__content__img {
    opacity: 28%;
  }

  .off__list__item__content {
    flex-grow: 100;
    opacity: 1;
    display: block;
    padding: 0px 0px 0px 16px !important;
  }

  .txt__menu__timeLeft {
    font-size: 12px;
    color: #686c6d;
  }

  .txt__title__menu {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    width: 81%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .off__txt__menu {
    color: #686c6d;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    width: 81%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .content__title__menu {
    margin-top: 0px;
  }

  .off__title__menu {
    margin-top: 6px;
  }

  .content__description__menu {
    display: flex;
    margin-top: -3px;
  }

  .off__cont__list__img {
    width: 52px;
    height: 52px;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
    display: inline-block;
    justify-self: end;
    position: relative;
    top: -50px;
  }
  .icon-time {
    margin-right: 6px;
    font-size: 16px;
    color: #686c6d;
  }

  .modal__base-hide {
    display: none; /* Hidden by default */
  }

  .modal__base {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Stay in place */
    z-index: 105; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .modal {
    display: block !important;
    z-index: 106 !important;
    height: auto;
    transform: scaleX(1) scaleY(1);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding-bottom: 32px;
    opacity: 1;
    margin: 0 auto;
  }

  .modal__cont {
    padding: 0px;
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .modal__cont::-webkit-scrollbar {
    display: none;
  }

  .cont__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 40px 0px 40px;
    text-align: left;
  }

  .cont__message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 40px 0px 40px;
    text-align: left;
  }

  .text--exit {
    font-size: 1rem;
    font-weight: 500;
    color: #f4f4f4;
  }
  .text--exit.white2 {
    color: #f4f4f4;
    font-weight: 600 !important;
  }
  .text--exit.dark2 {
    color: #212121;
    font-weight: 600 !important;
  }

  .text_-message {
    font-size: 1rem;
  }

  .text__title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .cont__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 10px;
  }

  .button--return {
    background: transparent;
    border: none;
    box-shadow: none;
    margin: 0px 20px 0px 20px !important;
  }

  .button--delete {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    margin: 0px !important;
  }

  .button--delete > .text--delete {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-transform: initial;
  }

  .text--return {
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
  }

  .form__cont {
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background: white;
    border-radius: 20px 20px 0px 0px;
    padding: 0px;
  }

  .form__cont::-webkit-scrollbar {
    display: none;
  }

  .alert-condition {
    padding: 16px;
    display: flex;
    box-shadow: 0px 0px 8px #00000029;
    background-color: var(--backColorAlert);
    border-radius: 8px;
    bottom: 17%;
    position: fixed;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .color__terms {
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
  }

  @media only screen and (min-width: 20px) {
    .modal {
      border-radius: 20px 20px 0px 0px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .text--return {
      font-size: 1rem;
    }

    .text--delete {
      font-size: 1rem;
    }

    .icon__time__modal {
      display: flex;
      justify-content: center;
      padding-top: 32px;
    }

    .schedule__icon {
      font-size: 56px !important;
      position: relative;
      top: 8.5px;
      display: flex;
      justify-content: center;
      color: #686c6d;
    }
    .back__color__icon {
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 72px;
      height: 72px;
    }

    .cont__message {
      justify-content: center;
      padding: 8px 24px 0px 24px;
    }

    .text_-message {
      font-size: 14px;
    }

    .cont__title {
      justify-content: center;
      padding: 16px 24px 0px 24px;
    }

    .text__title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 556px) {
    .alert-condition {
      width: 90%;
    }
  }

  @media only screen and (max-width: 631px) {
    .alert-condition {
      width: 90%;
    }
  }

  .alert-condition i {
    color: #ffa64d;
    margin-right: 15px;
    margin-left: 5px;
  }

  @media only screen and (min-width: 1200px) {
    .modal {
      width: 38%;
      border-radius: 12px;
      position: relative;
    }
    .text--return {
      font-size: 1rem;
    }

    .text--delete {
      font-size: 1rem;
    }

    .icon__time__modal {
      display: flex;
      justify-content: center;
      padding-top: 32px;
    }

    .schedule__icon {
      font-size: 56px !important;
      position: relative;
      top: 8.5px;
      display: flex;
      justify-content: center;
      color: #686c6d;
    }
    .back__color__icon {
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 72px;
      height: 72px;
    }

    .cont__message {
      justify-content: center;
      padding: 8px 24px 0px 24px;
    }

    .text_-message {
      font-size: 14px;
    }

    .cont__title {
      justify-content: center;
      padding: 16px 24px 0px 24px;
    }

    .text__title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .div__terms__cond {
    text-align: left;
  }

  @media only screen and (min-width: 20px) and (max-width: 699px) {
    .div__terms__cond {
      padding: 62px 13px 0px 13px !important;
    }
  }

  @media only screen and (min-width: 700px) and (max-width: 1200px) {
    .div__terms__cond {
      margin: -30px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .div__terms__cond {
      margin: -31px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1419px) {
    .div__terms__cond {
      margin: -64px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1420px) and (max-width: 1549px) {
    .div__terms__cond {
      margin: -36px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1550px) and (max-width: 1799px) {
    .div__terms__cond {
      margin: -36px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 20px) and (max-width: 396px) {
    .fix__short {
      margin-top: 14px !important;
    }
  }

  .chip__div {
    padding-top: 10px !important;
  }

  .fix__short {
    position: relative !important;
    top: 4px !important;
  }

  .div__bottom {
    padding-bottom: 140px !important;
  }

  .line__elipsis {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
  .cont__terms {
    padding: 1px 16px 1px 16px;
    background: var(--backColorPromotions);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.175);
  }

  .term__and__cond {
    padding-top: 120px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 20px) {
    .term__and__cond {
      max-width: 95%;
    }
  }

  @media (min-width: 633px) {
    .term__and__cond {
      max-width: 65%;
    }
  }
  @media (max-width: 633px) {
    .my-footer-button {
      padding: 0;
    }
  }

  @media (min-width: 1200px) {
    .term__and__cond {
      max-width: 45%;
    }
  }

  @media (min-width: 1600px) {
    .term__and__cond {
      max-width: 36%;
    }
  }
</style>
