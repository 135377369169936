<script>
  import { _, locale } from "svelte-i18n";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import ComponentOutlet from "../../components/types-requests/outlet-component.svelte";
  import CardRequest from "../../components/types-requests/request-component.svelte";
  import CardCheckIn from "../../components/types-requests/check-in-component.svelte";
  import CardCheckInDate from "../../components/types-requests/check-in-date-component.svelte";
  import StatusBar from "../../components/status-bar/component.svelte";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import ModalMobile from "../../components/modal/mobile/component.svelte";
  import ModalDesktop from "../../components/modal/desktop/component.svelte";
  import PropertyFeatures from "../../property-features-service";
  import Messages from "../messages/component.svelte";
  import { push } from "svelte-spa-router";
  import LandingPage from "../../landing-page/list-features/component.svelte";
  import environments from "../../environments";
  export let params = {};
  import FeedbackModal from "../../components/navigation/feedback.modal.svelte";
  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let dataRequest = null;
  let managementColors;
  let faeturesList = [];
  let languagesEnabled = [];
  let language;
  let showModalFeed = false;
  let showBtnBack = true;
  let propEmpty = "";
  let reload = true;
  let filterSvg = false;
  let stateStates = "initial";
  let defaultStyles = true;
  let titleText = "";
  let descriptionText = "";
  let newtext = "";
  let acceptText = "";
  let finishedText = "";
  let showMessages = false;
  let modalCancelRequest = false;
  let showForm = false;
  let auth = JSON.parse(localStorage.getItem("auth"));
  let mobileScreen = true;
  let screenPxs = window.innerWidth;
  let resizeCompoment = true;
  let settingsData;
  let optionsForm = [
    {
      nameOption: "There was a mistake/I changed my mind",
      nameOptionEs: "Hubo un error/Cambié de opinión",
    },
    {
      nameOption: "It is taking too long",
      nameOptionEs: "Está tardando demasiado",
    },
    {
      nameOption: "Other",
      nameOptionEs: "Otro",
    },
  ];

  let dataHeader = JSON.parse(localStorage.getItem("dataHeader"));
  let landing;

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  let onFinishedLoading = () => {
    landing = true;
    validationColor();
    init(
      localStorage.getItem("language")
        ? JSON.parse(localStorage.getItem("language"))
        : window.navigator.language.split("-")[0]
    );
  };

  const init = (lang) => {
    dataRequest = dataRequest;
    console.log(dataRequest);
    resizeView();
    Promise.all([
      PropertyFeatures.list({ propertyId: state.propertyId }, lang),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
      PropertyFeatures.eventsEndpoint({
        propertyId: state.propertyId,
        reservationId: state.reservationId,
      }),
      Promise.resolve(getRequestData("create", 0)),
    ]).then(
      ([features, guestappsettins, propertyInformation, eventSource, get]) => {
        propertyData = propertyInformation;
        settingsData = guestappsettins;
        if (dataRequest && propertyData) reload = false;

        faeturesList = features.filter(
          (fet) => fet.enabled || fet.codename == "property.details"
        );
        dataHeader.nameFeature = dataRequest.outletName
          ? dataRequest.outletName
          : dataRequest.serviceName;
        dataHeader.imgFeature = dataRequest.outletPhoto
          ? dataRequest.outletPhoto
          : dataRequest.serviceImg;
        localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
        localStorage.setItem("lastRoute", '"viewmyrequests"');

        validationLanguage(
          guestappsettins.languages,
          propertyInformation &&
            propertyInformation.language &&
            propertyInformation.language.code
            ? propertyInformation.language.code
            : "en"
        );
        setFontFamily(settingsData.fontFamily);
      }
    );
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  const calculatePrices = (dataRequest) => {
    dataRequest.typeMoney
      ? (dataRequest.typeMoney = dataRequest.typeMoney)
      : (dataRequest.typeMoney = "$");
    dataRequest.total = 0;
    if (dataRequest.items)
      dataRequest.items.map((item) => {
        item.typeMoney = dataRequest.typeMoney ? dataRequest.typeMoney : "$";
        item.pricebase = item.price * item.cant;
        item.pricebase = parseFloat(item.pricebase).toFixed(2);
        let opprice = 0;
        item.modifiers.map((mod) => {
          mod.options.map((op) => {
            if (parseFloat(op.price)) {
              op.pricebase = op.price * item.cant;
              op.pricebase = parseFloat(op.pricebase).toFixed(2);
              opprice = parseFloat(opprice) + parseFloat(op.pricebase);
            } else {
              op.pricebase = 0;
            }
          });
        });
        item.totalprice = parseFloat(item.pricebase) + parseFloat(opprice);
        item.totalprice = parseFloat(item.totalprice).toFixed(2);

        dataRequest.total =
          parseFloat(dataRequest.total) + parseFloat(item.totalprice);
      });
  };

  let getRequestData = (e, id) => {
    if (e == "double" && id == state.id) {
      goMyRequests();
    } else if ((e == "single" && id == state.id) || e == "create") {
      return PropertyFeatures.requestDetail({
        propertyId: state.propertyId,
        reservationId: state.reservationId,
        id: state.id,
      }).then((response) => {
        if (response.type == "check-in" && response.status == "Declined") {
          window.location.href = environments.guestapp.web
            .concat("/")
            .concat(state.propertyId)
            .concat("/my-request/")
            .concat(state.id)
            .concat(
              `?${auth && auth.code ? `code=${auth.code}&` : ""}language=` +
                JSON.parse(localStorage.getItem("language"))
            );
          return;
        }
        dataRequest = response;
        calculatePrices(dataRequest);
        validationStatusFlow(dataRequest.status, dataRequest.type);
        validationSvg(dataRequest);
        return true;
      });
    }
  };

  let resizeView = () => {
    if (screenPxs >= 600) mobileScreen = false;

    window.addEventListener("resize", () => {
      resizeCompoment = false;
      screenPxs = window.innerWidth;

      if (screenPxs >= 600) {
        mobileScreen = false;
      } else {
        mobileScreen = true;
      }

      setTimeout(() => {
        resizeCompoment = true;
      }, 1);
    });
  };

  let validationStatusFlow = (status, type) => {
    switch (type) {
      case "request":
        newtext = "Validate";
        acceptText = "Accepted";
        finishedText = "Finished";
        if (status == "Validate") {
          titleText = "we are validating your request";
        } else if (status == "Accepted") {
          titleText = "Your request has been accepted, is already in process";
          setTimeout(() => {
            stateStates = "initial";
          }, 1000);
        } else if (status == "Finished") {
          titleText = "Your request has been completed";
          setTimeout(() => {
            stateStates = "Finished";
            setFontFamily(null);
          }, 1000);
        } else if (status == "Cancelled") {
          titleText = "Your request has been cancelled";
          descriptionText = dataRequest.cancelledReason;
          setTimeout(() => {
            stateStates = "Cancelled";
            setFontFamily(null);
          }, 1000);
        }

        break;
      case "food-beverage":
      case "service":
        newtext = "Validate";
        acceptText = "Processed";
        finishedText = "Delivered";
        if (status == "Validate") {
          titleText = "We are validating your order";
        } else if (status == "Processed") {
          titleText = "Your order is in process";
          setTimeout(() => {
            stateStates = "initial";
          }, 1000);
        } else if (status == "Delivered") {
          titleText = "Your order has been delivered";
          setTimeout(() => {
            stateStates = "Finished";
            setFontFamily(null);
          }, 1000);
        } else if (status == "Cancelled") {
          titleText = "Your order has been cancelled";
          descriptionText = dataRequest.cancelledReason;
          setTimeout(() => {
            stateStates = "Cancelled";
            setFontFamily(null);
          }, 1000);
        }
        break;
      case "check-in":
        newtext = "Validate";
        acceptText = "Accepted";
        finishedText = "Confirmed";
        if (status == "Validate") {
          titleText = "We are validating your data";
          stateStates = "initial";
        } else if (status == "Accepted") {
          titleText = "Your data has been accepted";
          setTimeout(() => {
            stateStates = "initial";
          }, 1000);
        } else if (status == "Confirmed") {
          titleText = "Your data has been confirmed";
          setTimeout(() => {
            stateStates = "Finished";
            setFontFamily(null);
          }, 1000);
        } else if (status == "Declined") {
          titleText = "Your data has been declined";
          setTimeout(() => {
            stateStates = "Cancelled";
            setFontFamily(null);
          }, 1000);
        } else if (status == "Cancelled") {
          titleText = "Your data has been cancelled";
          setTimeout(() => {
            stateStates = "Cancelled";
            setFontFamily(null);
          }, 1000);
        }
        break;
      default:
    }
  };

  let validationSvg = (e) => {
    if (e.serviceImg && e.serviceImg.split("/").find((sp) => sp == "img"))
      filterSvg = true;
  };

  let handleMessages = () => {
    showMessages = !showMessages;
    if (dataRequest.comments.find((message) => !message.read)) {
      PropertyFeatures.readMessage(dataRequest.propertyId, {}, dataRequest.id);
    }
  };

  let handleFeed = () => {
    if (["active", "skiped"].includes(dataRequest.feedback.state)) {
      showModalFeed = true;
    }
  };

  let exit = () => {
    push(`/properties/${state.propertyId}`);
  };

  let goMyRequests = () => {
    push(`/properties/${state.propertyId}/my-experiences`);
  };

  let cancelRequest = () => {
    modalCancelRequest = !modalCancelRequest;
  };

  let handleModal = () => {
    modalCancelRequest = false;
  };

  let confirmCancelRequest = () => {
    showForm = true;
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  if (
    localStorage.getItem("languagesConfig") &&
    localStorage.getItem("dataHeader") &&
    localStorage.getItem("managementColors") &&
    localStorage.getItem("fontFamily") &&
    localStorage.getItem("browser-identify") &&
    localStorage.getItem("reservation") &&
    localStorage.getItem("auth")
  ) {
    landing = true;
    onFinishedLoading();
  } else {
    landing = false;
  }

  let handleLanguage = (e) => {
    init(e);
  };

  const validationLanguage = (enabledLanguges, languageDefault) => {
    localStorage.setItem("languagesConfig", JSON.stringify(enabledLanguges));
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let sendOptionCancel = (option) => {
    let input = {
      propertyId: state.propertyId,
      reservationId: state.reservationId,
      id: state.id,
    };

    let body = {
      guestName:
        auth && auth.guestName ? auth.guestName : dataRequest.guestName,
      reason: !optionsForm.find((o) => o.nameOption == option)
        ? option
        : localStorage.getItem("language") == '"es"'
          ? optionsForm
              .find((o) => option != "Other" && o.nameOption == option)
              .nameOptionEs.concat(" / ")
              .concat(
                optionsForm.find(
                  (o) => option != "Other" && o.nameOption == option
                ).nameOption
              )
          : optionsForm
              .find((o) => option != "Other" && o.nameOption == option)
              .nameOption.concat(" / ")
              .concat(
                optionsForm.find(
                  (o) => option != "Other" && o.nameOption == option
                ).nameOptionEs
              ),
    };

    PropertyFeatures.cancelRequest(input, body).then((response) => {
      modalCancelRequest = false;
      getRequestData("create", 0);
    });
  };

  let outPutUpdateData = (e, requestId) => {
    getRequestData(e, requestId);
  };

  function closeFeedback(ev) {
    console.log(ev);
    showModalFeed = false;
  }
</script>

{#if !landing}
  <div style="display:block">
    <LandingPage bind:params page={"home"} bind:onFinishedLoading />
  </div>
{:else if reload}
  <div
    class="container-fluid reloading row"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <Reloading bind:managementColors />
  </div>
{:else}
  <div
    class="col s12 completed__request row"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <div
      class="col s12 completed__request__cont row"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'white'
          ? 'navifation-cont-border--white'
          : managementColors.general.colorMode == 'dark'
            ? 'navifation-cont-border--dark'
            : ''}"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <NavigationTop
          bind:languageConection={handleLanguage}
          bind:logoUrl={propertyData.logo}
          bind:propertyName={propertyData.name}
          bind:propertyId={state.propertyId}
          bind:lang={language}
          bind:listLanguagesEnabled={languagesEnabled}
          bind:features={faeturesList}
          bind:backgroundColorView={managementColors.general.backgroundColor}
          bind:colorLetter={managementColors.general.colorLetter}
          bind:managementColors
          bind:color={managementColors.color}
          bind:showBtnBack
          bind:iframeFromLanding={propEmpty}
          bind:outPutUpdateData
        />
      </div>
      {#if dataRequest.status == "Declined"}
        <div
          class="container-fluid reloading row"
          use:styling={{ background: managementColors.general.backgroundColor }}
        >
          <Reloading bind:managementColors />
        </div>
      {:else}
        <div
          class="col s12 status__request"
          use:styling={{
            background: managementColors.general.backgroundDivFotter,
          }}
        >
          {#if dataRequest.status == "Validate" && dataRequest.type != "check-in"}
            {#if dataRequest.status == "Validate"}
              <div class="col s12 status__request__title">
                <span
                  class="status__request__title__text {$locale}"
                  use:styling={{ color: managementColors.general.colorLetter }}
                  >{$_("One moment...")}</span
                >
              </div>
            {/if}
          {/if}

          <div class="col s12 status__request__title">
            <span
              class="status__request__title__text {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
              >{$_(titleText)}</span
            >
          </div>

          <div class="col s12 status__request__title--desktop">
            {#if dataRequest.status == "Validate" && dataRequest.type != "check-in"}
              {#if dataRequest.status == "Validate"}
                <span
                  class="status__request__title__text {$locale}"
                  use:styling={{ color: managementColors.general.colorLetter }}
                  >{$_("One moment...")}</span
                > &nbsp;
              {/if}
            {/if}
            <span
              class="status__request__title__text {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
              >{$_(titleText)}</span
            >
          </div>

          {#if dataRequest.status == "Cancelled"}
            <div class="col s12 status__request__description">
              <!-- <span
                class="status__request__description__text {$locale}"
                use:styling={{ color: managementColors.general.colorLetter }}
                >{$_(descriptionText)}</span
              > -->
            </div>
          {/if}

          <div class="col xl12 l12 m12 s12 status__request__progress__bar">
            {#if resizeCompoment}
              <StatusBar
                bind:managementColors
                bind:status={dataRequest.status}
                bind:stateStates
                bind:newtext
                bind:acceptText
                bind:finishedText
              />
            {/if}
          </div>

          <div class="col s12 status__request__link">
            {#if stateStates == "Finished"}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <span
                class="status__request__link__text {$locale}"
                use:styling={{
                  color: managementColors.general.colorletterButtonLInks,
                }}
                on:click={goMyRequests}
              >
                {$_("View my experiences")}
              </span>
            {/if}

            {#if dataRequest.status == "Validate" && dataRequest.type == "check-in"}
              <span
                class="status__request__link__text {$locale}"
                use:styling={{
                  color: managementColors.general.colorletterButtonLInks,
                }}
                style="text-decoration: none; cursor: default"
                >{$_(
                  "We will send you an email when the validation of your data is completed."
                )}</span
              >
            {:else if dataRequest.status == "Validate" && dataRequest.type == "request"}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <span
                class="status__request__link__text {$locale}"
                use:styling={{
                  color: managementColors.general.colorletterButtonLInks,
                }}
                on:click={cancelRequest}>{$_("Cancel your request")}</span
              >
            {:else if dataRequest.status == "Validate" && (dataRequest.type == "food-beverage" || dataRequest.type == "service")}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <span
                class="status__request__link__text {$locale}"
                use:styling={{
                  color: managementColors.general.colorletterButtonLInks,
                }}
                on:click={cancelRequest}>{$_("Cancel your order")}</span
              >
            {/if}
          </div>
        </div>
        <div class="col s12 completed__request__body col xl12 l12 m12 s12">
          <div class="col s12 info__request">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            {#if dataRequest.type == "check-in"}
              <CardCheckInDate
                bind:dataRequest
                bind:managementColors
                bind:filterSvg
                bind:defaultStyles
              />
            {/if}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            {#if dataRequest.feedback}
              <!-- content here -->
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <div
                class="col s12 card card--message pointer ' {dataRequest
                  .feedback?.statusCodename === 'positive'
                  ? 'positive-feedback'
                  : dataRequest.feedback?.statusCodename === 'negative'
                    ? 'negative-feedback'
                    : 'card--message'}"
                use:styling={{
                  background: managementColors.general.backgroundColorCards,
                }}
                on:click={handleFeed}
              >
                <div
                  class=" {dataRequest.feedback?.statusCodename ===
                    'positive' ||
                  dataRequest.feedback?.statusCodename === 'negative'
                    ? 'positive-feedbackText'
                    : 'cont__header-icon--mood'}"
                >
                  {#if !dataRequest.feedback?.statusCodename}
                    <i
                      use:styling={{
                        color: managementColors.general.backColorPromotions,
                      }}
                      class="material-icons feedback__neutral">question_mark</i
                    >
                  {/if}
                  {#if dataRequest.feedback?.statusCodename == "positive"}
                    <i class="material-icons feedback__green">mood</i>
                  {/if}
                  {#if dataRequest.feedback?.statusCodename == "negative"}
                    <i class="material-icons feedback__red">mood_bad</i>
                  {/if}
                  <!-- <i class="material-icons icon--messege"> ? </i> -->
                  <!-- {#if dataRequest.comments.find((message) => !message.read)}
                     <div class="card--message__badge" />
                   {/if} -->
                </div>

                <div class="card--message__text">
                  {#if dataRequest.feedback?.statusCodename == "positive"}
                    <div class="col s12 card--message__text__one">
                      <span
                        class="message__text__one message_text_mod{$locale}"
                        use:styling={{
                          color: managementColors.general.colorLetter,
                        }}
                      >
                        {$_("Like it")}
                      </span>
                    </div>
                  {/if}
                  {#if dataRequest.feedback?.statusCodename == "negative"}
                    <div class="col s12 card--message__text__one">
                      <span
                        class="message__text__one message_text_mod{$locale}"
                        use:styling={{
                          color: managementColors.general.colorLetter,
                        }}
                      >
                        {$_("I didn't like")}
                      </span>
                    </div>
                    <div
                      class="feedComment {$locale}"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {$_(dataRequest.feedback.commentSurvey)}
                    </div>
                  {/if}
                  {#if !dataRequest.feedback?.statusCodename}
                    <div class="col s12 card--message__text__one">
                      <span
                        class="message__text__one message_text_mod{$locale}"
                        use:styling={{
                          color: managementColors.general.colorLetter,
                        }}
                      >
                        {$_("Did you like the service offered?")}
                      </span>
                    </div>
                  {/if}
                </div>
              </div>
            {/if}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <div
              class="col s12 card card--message pointer"
              use:styling={{
                background: managementColors.general.backgroundColorCards,
              }}
              on:click={handleMessages}
            >
              <div class="card--message__icon">
                <i class="material-icons icon--messege"> forum </i>
                {#if dataRequest.comments.find((message) => !message.read)}
                  <div class="card--message__badge" />
                {/if}
              </div>

              <div class="card--message__text">
                <div class="col s12 card--message__text__one">
                  <span
                    class="message__text__one {$locale}"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {$_("Messages")}
                  </span>
                </div>
                <div class="col s12 card--message__text__two">
                  <span
                    class="message__text__two {$locale}"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {$_("Comments and suggestions")}
                  </span>
                </div>
              </div>
            </div>
            {#if dataRequest.type == "request"}
              <div
                class="col s12 card card--request"
                use:styling={{
                  background: managementColors.general.backgroundColorCards,
                }}
              >
                <CardRequest
                  bind:dataRequest
                  bind:managementColors
                  bind:filterSvg
                  bind:defaultStyles
                />
              </div>
            {:else if dataRequest.type == "check-in"}
              <CardCheckIn
                bind:dataRequest
                bind:managementColors
                bind:filterSvg
                bind:defaultStyles
              />
            {:else}
              <div
                class="col s12 card card--request"
                use:styling={{
                  background: managementColors.general.backgroundColorCards,
                }}
              >
                <ComponentOutlet
                  bind:dataRequest
                  bind:managementColors
                  bind:filterSvg
                  bind:defaultStyles
                />
              </div>
            {/if}

            {#if dataRequest.type != "check-in"}
              <div
                class="col s12 card card--location"
                use:styling={{
                  background: managementColors.general.backgroundColorCards,
                }}
              >
              <div class="card--message__icon" 
              use:styling={{ background: managementColors.color }}>
                <i    use:styling={{
                  color: managementColors.general.backColorPromotions,
                }} class="material-icons icon--location"> location_on </i>
              </div>
                <div class="card--location__text">
                  <div class="col s12 card--location__text__one">
                    <span
                      class="location__text__one {$locale}"
                      use:styling={{
                        color: managementColors.general.colorLetterNumId,
                      }}
                    >
                      {$_("Delivery place")}
                    </span>
                  </div>
                  <div class="col s12 card--location__text__two">
                    <span
                      class="location__text__two {$locale}"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {#if dataRequest.publicArea}
                        {dataRequest.publicArea}
                      {:else}
                        {dataRequest.roomNumber}
                      {/if}
                    </span>
                  </div>
                </div>
              </div>
            {/if}

            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- <div
              class="col s12 card card--message pointer"
              use:styling={{
                background: managementColors.general.backgroundColorCards,
              }}
              on:click={handleMessages}
            >
              <div class="card--message__icon">
                <i class="material-icons icon--messege"> forum </i>
                {#if dataRequest.comments.find((message) => !message.read)}
                  <div class="card--message__badge" />
                {/if}
              </div>

              <div class="card--message__text">
                <div class="col s12 card--message__text__one">
                  <span
                    class="message__text__one {$locale}"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {$_("Messages")}
                  </span>
                </div>
                <div class="col s12 card--message__text__two">
                  <span
                    class="message__text__two {$locale}"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {$_("Comments and suggestions")}
                  </span>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        {#if stateStates == "Finished" || stateStates == "Cancelled"}
          <div
            class="col s12 footer {managementColors.general.colorMode == 'white'
              ? 'footer-border--white'
              : managementColors.general.colorMode == 'dark'
                ? 'footer-border--dark'
                : ''}"
            use:styling={{
              background: managementColors.general.backgroundColor,
            }}
          >
            <button
              class=" col s12 l3 xl2 btn-success button--exit {managementColors
                .general.colorMode}2"
              on:click={exit}
            >
              <span
                class="text--exit {$locale} {managementColors.general
                  .colorMode}2"
              >
                {$_("Exit")}
              </span>
            </button>
          </div>
        {/if}
        {#if showMessages}
          <Messages
            bind:dataRequest
            bind:managementColors
            bind:closeMessages={handleMessages}
          />
        {/if}
        {#if modalCancelRequest && mobileScreen && dataRequest.type == "request"}
          <ModalMobile
            title="Do you want to cancel the request?"
            message="Canceling the request will prevent the case from being followed up"
            returnText="No, go back"
            actionText="Yes, sure"
            formTitle="What is your reason for cancelling this request?"
            bind:propertyId={state.propertyId}
            bind:managementColors
            bind:showForm
            bind:optionsForm
            bind:closeModal={handleModal}
            bind:onAction={confirmCancelRequest}
            bind:sendOptionCancel
          />
        {:else if modalCancelRequest && !mobileScreen && dataRequest.type == "request"}
          <ModalDesktop
            title="Do you want to cancel the request?"
            message="Canceling the request will prevent the case from being followed up"
            returnText="No, go back"
            actionText="Yes, sure"
            formTitle="What is your reason for cancelling this request?"
            bind:propertyId={state.propertyId}
            bind:managementColors
            bind:showForm
            bind:optionsForm
            bind:closeModal={handleModal}
            bind:onAction={confirmCancelRequest}
            bind:sendOptionCancel
          />
        {/if}
        {#if modalCancelRequest && mobileScreen && (dataRequest.type == "food-beverage" || dataRequest.type == "service")}
          <ModalMobile
            title="Do you want to cancel the order?"
            message="Canceling the order will prevent the case from being followed up"
            returnText="No, go back"
            actionText="Yes, sure"
            formTitle="What is your reason for cancelling this order?"
            bind:propertyId={state.propertyId}
            bind:managementColors
            bind:showForm
            bind:optionsForm
            bind:closeModal={handleModal}
            bind:onAction={confirmCancelRequest}
            bind:sendOptionCancel
          />
        {:else if modalCancelRequest && !mobileScreen && (dataRequest.type == "food-beverage" || dataRequest.type == "service")}
          <ModalDesktop
            title="Do you want to cancel the order?"
            message="Canceling the order will prevent the case from being followed up"
            returnText="No, go back"
            actionText="Yes, sure"
            formTitle="What is your reason for cancelling this order?"
            bind:propertyId={state.propertyId}
            bind:managementColors
            bind:showForm
            bind:optionsForm
            bind:closeModal={handleModal}
            bind:onAction={confirmCancelRequest}
            bind:sendOptionCancel
          />
        {/if}
      {/if}
    </div>
    {#if showModalFeed}
      <FeedbackModal
        id={dataRequest.feedback.id}
        propertyId="{dataRequest.propertyId},"
        reservationId="{dataRequest.reservationId},"
        browserIdentify{browserIdentify}
        {managementColors}
        on:finish={closeFeedback}
      ></FeedbackModal>
    {/if}
  </div>
{/if}

<style>
  .feedComment {
    /* Desktop/Text 4 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 11px;
    line-height: normal;
    word-break: break-word;
  }
  .cont__header-icon--mood {
    border-radius: 8px;
    text-align: center;
    width: 50px;
    height: 50px;
    background-color: var(--dynamic-color);
  }

  .feedback__green {
    color: var(----gh-color-dynamic-1, #1ab394);
    text-align: center;
    font-family: "Material Icons";
    font-size: 40px;
  }

  .feedback__red {
    color: var(----gh-color-red, #ff4855);
    text-align: center;
    font-family: "Material Icons";
    font-size: 40px;
  }
  .feedback__neutral {
    font-family: "Material Icons";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    margin-top: 1rem;
  }
  .feedback__neutral--white {
    color: #fff;
  }
  .feedback__neutral--dark {
    color: #212121;
  }

  .reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .completed__request {
    height: 100%;
    margin: 0px;
  }

  .completed__request__cont {
    height: auto;
    padding: 0px 0px 100px 0px !important;
  }

  .text-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .completed__request__body {
    position: relative;
    top: 60px;
    padding: 0px !important;
    display: flex;
  }

  .status__request {
    position: relative;
    top: 60px;
    padding: 16px !important;
    background: #f4f4f4;
  }

  .info__request {
    padding: 32px 0px 0px 0px !important;
  }

  .status__request__title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status__request__description {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px 0px 0px;
  }

  .status__request__title--desktop {
    display: none;
  }

  .status__request__title__text {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .status__request__description__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    opacity: 1;
  }

  .status__request__progress__bar {
    height: 70px;
    padding: 16px 13px 0px 13px !important;
  }

  .status__request__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0px 0px 0px !important;
  }

  .status__request__link__text {
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: underline;
    letter-spacing: 0px;
    opacity: 1;
    cursor: pointer;
  }

  .card--request {
    padding: 0px 0px 0px 0px !important;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    opacity: 1;
  }

  .pointer {
    cursor: pointer;
  }

  .positive-feedback {
    display: flex;
    align-items: center;
    padding: 16px !important;
    border-radius: 8px;
    background: var(----gh-color-dynamic-2, rgba(26, 179, 148, 0.2)) !important;
    pointer-events: none !important;
  }
  .negative-feedback {
    display: flex;
    align-items: center;
    padding: 16px !important;
    border-radius: 8px;
    pointer-events: none !important;
    background: var(----gh-color-light-red, rgba(255, 72, 85, 0.16)) !important;
  }
  .positive-feedbackText {
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }
  .card--message {
    display: flex;
    align-items: center;
    padding: 16px !important;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    opacity: 1;
  }

  .div__info__service {
    display: flex;
    align-items: center;
    padding: 0px 16px 19px 16px !important;
  }

  .card--message__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 44px;
    height: 44px;
    background: #363636 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    float: left;
    opacity: 1;
  }

  .icon--messege {
    font-size: 1.25rem;
    letter-spacing: 0px;
    color: #f4f4f4;
    opacity: 1;
  }

  .icon--location {
    font-size: 1.25rem;
    letter-spacing: 0px;
    color: #f4f4f4;
    opacity: 1;
  }

  .message__text__one {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .message__text__two {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    height: 86px;
    box-shadow: 0px 0px 24px #00000014;
    opacity: 1;
  }

  .footer-border--white {
    border-top: 1px solid #e3e3e3;
  }

  .footer-border--dark {
    border-top: 1px solid #686c6d;
  }

  .button--exit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 38px;
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    line-height: 36px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    opacity: 1;
    cursor: pointer;
  }
  .button--exit.white2 {
    background: #212121 0% 0% no-repeat padding-box;
  }
  .button--exit.dark2 {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  .button--exit:hover {
    box-shadow: 0px 4px 8px #3636368a;
  }

  .text--exit {
    font-size: 1rem;
    font-weight: 500;
    color: #f4f4f4;
    text-transform: capitalize;
  }
  .text--exit.white2 {
    color: #f4f4f4;
  }
  .text--exit.dark2 {
    color: #212121;
  }

  .card--location {
    display: flex;
    align-items: center;
    padding: 16px !important;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    margin-bottom: 170px;
    opacity: 1;
  }

  .card--location__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    float: left;
    opacity: 1;
  }

  .location__text__one {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  .location__text__two {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .card--message__badge {
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #ff4855;
    top: 5px;
    right: 5px;
  }

  @media only screen and (min-width: 0px) {
    .info__request {
      width: 95% !important;
      margin: 0 auto;
    }

    .status__request__link__text {
      font-size: 16px;
      width: 100%;
      text-align: center;
    }

    .status__request__link {
      padding: 30px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 601px) {
    .status__request__title__text {
      font-size: 1.5rem;
    }

    .status__request__progress__bar {
      height: 130px;
      padding: 24px 0px 0px 0px !important;
    }

    .message__text__one {
      font-size: 1.125rem;
    }

    .message__text__two {
      font-size: 1rem;
    }

    .status__request__link__text {
      font-size: 16px;
      width: 57%;
      text-align: center;
    }

    .status__request__link {
      padding: 33px 0px 0px 0px !important;
    }

    .location__text__two {
      font-size: 1rem;
    }

    .status__request__description__text {
      font-size: 1rem;
    }

    .info__request {
      width: 65% !important;
      margin: 0 auto;
    }
  }

  @media only screen and (min-width: 992px) {
    .status__request__progress__bar {
      height: 190px;
      padding: 32px 0px 0px 0px !important;
    }

    .status__request__title__text {
      font-size: 2rem;
    }

    .message__text__one {
      font-size: 1.5rem;
    }

    .card--message__icon {
      width: 50px;
      height: 50px;
    }

    .icon--messege {
      font-size: 1.5rem;
    }

    .icon--location {
      font-size: 1.5rem;
    }

    .card--location__icon {
      width: 50px;
      height: 50px;
    }

    .status__request__title--desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .status__request__title {
      display: none;
    }
  }

  @media only screen and (min-width: 1200px) {
    .status__request__progress__bar {
      height: 190px;
      padding: 32px 0px 0px 0px !important;
    }

    .status__request__title__text {
      font-size: 2rem;
    }

    .message__text__one {
      font-size: 1.5rem;
    }

    .card--message__icon {
      width: 50px;
      height: 50px;
    }

    .icon--messege {
      font-size: 1.5rem;
    }

    .icon--location {
      font-size: 1.5rem;
    }

    .card--location__icon {
      width: 50px;
      height: 50px;
    }

    .info__request {
      width: 45% !important;
      margin: 0 auto;
    }

    .status__request__title--desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .status__request__title {
      display: none;
    }

    .status__request__link__text {
      font-size: 16px;
      width: 57%;
      text-align: center;
    }

    .status__request__link {
      padding: 0px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1600px) {
    .status__request__progress__bar {
      height: 190px;
      padding: 32px 0px 0px 0px !important;
    }

    .status__request__title__text {
      font-size: 2rem;
    }

    .message__text__one {
      font-size: 1.5rem;
    }

    .message_text_mod {
      color: var(----gh-color-black, #212121);
      /* Mobile/H3 */
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    .card--message__icon {
      width: 50px;
      height: 50px;
    }

    .icon--messege {
      font-size: 1.5rem;
    }

    .icon--location {
      font-size: 1.5rem;
    }

    .card--location__icon {
      width: 50px;
      height: 50px;
    }

    .info__request {
      width: 36% !important;
      margin: 0 auto;
    }

    .status__request__title--desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .status__request__title {
      display: none;
    }
  }
</style>
