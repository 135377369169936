<script>
  import DatesCustom from "../dates-custom/component.svelte";

  export let dataRequest;
  export let managementColors;
  export let filterSvg;
  export let defaultStyles;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<div class="col s12 div__date">
  <DatesCustom
    bind:date={dataRequest.executionDate}
    bind:colorLetterDates={managementColors.general.colorLetterDates}
    bind:defaultStyles
  /> &nbsp;
  <span
    class="request__id"
    use:styling={{ color: managementColors.general.colorLetterNumId }}
    >#{dataRequest.idReference}</span
  >
</div>

<div class="col s12 div__info__service">
  <div
    use:styling={{ background: managementColors.color }}
    class="{dataRequest.serviceName === "Promotions" || dataRequest.serviceName === "Promociones" ? 'cont__header-icon--create' : 'cont__header-icon--svg'}"
  >
    {#if dataRequest.serviceName === "Promotions" || dataRequest.serviceName === "Promociones"}
      <img
        class="header-icon--completed"
        alt="img"
        src={dataRequest.outletPhoto}
      />
    {:else}
      <img
        class={filterSvg && managementColors.general.colorMode == "white"
          ? "header-icon--white"
          : filterSvg && managementColors.general.colorMode == "dark"
            ? "header-icon--dark"
            : "header-icon--completed"}
        alt="img"
        src={dataRequest.serviceImg}
      />
    {/if}
  </div>

  <span
    class="header_nameproperty"
    use:styling={{ color: managementColors.general.colorLetter }}
  >
    {#if dataRequest.serviceName === "Promotions" || dataRequest.serviceName === "Promociones"}
      {dataRequest.outletName}
    {:else}
      {dataRequest.serviceName}
    {/if}
  </span>
</div>

<!-- {#if dataRequest.status != "Validate" && dataRequest.categoryName && dataRequest.subcategoryName}
  <div class="col s12 div__text">
    <span
      class="div__name__text"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {dataRequest.subcategoryName}
    </span>
    <span
      class="div__name__text"
      use:styling={{ color: managementColors.color }}
    >
      /
    </span>
    <span
      class="div__name__text--category"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {dataRequest.categoryName}
    </span>
  </div>
{/if} -->
{#if dataRequest.categoryName != "Promotion" && dataRequest.categoryName != "Promocion"}
  <div class="col s12 div__text">
    <span
      class="div__name__text"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {dataRequest.requestText || ""}
    </span>
  </div>
{/if}

<style>
  .div__text {
    padding: 0px 16px 16px 16px !important;
    word-break: break-word;
  }

  .div__name__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
  }

  .div__name__text--category {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .div__info__service {
    display: flex;
    align-items: center;
    padding: 0px 16px 19px 16px !important;
  }

  .cont__header-icon--create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    opacity: 1;
  }

  .cont__header-icon--svg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    opacity: 1;
  }

  .header-icon--white {
    width: 28px;
    height: 28px;
    filter: invert(100%) sepia(6%) saturate(755%) hue-rotate(169deg)
      brightness(117%) contrast(100%);
  }

  .header-icon--dark {
    width: 28px;
    height: 28px;
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%);
  }

  .header-icon--completed {
    width: 40px;
    height: 100%;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    object-fit:cover ;
    cursor: pointer;
    box-shadow: 0px 4px 8px #00000029;
  }

  .header_nameproperty {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .div__date {
    padding: 16px 16px 4px 16px !important;
  }

  .request__id {
    font-size: 0.875rem;
    font-weight: 700;
  }

  @media only screen and (min-width: 601px) {
    .div__name__text {
      font-size: 1rem;
    }

    .div__name__text--category {
      font-size: 1rem;
    }

    .header_nameproperty {
      font-size: 1.125rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .header_nameproperty {
      font-size: 1.5rem;
    }
  }
</style>
