<script>
  import { _, locale } from "svelte-i18n";
  import DatesCustom from "../dates-custom/component.svelte";

  export let dataRequest;
  export let managementColors;
  export let colorBorder;
  export let filterSvg;
  export let defaultStyles;

  let seeDetail = false;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleSeeDetail = () => {
    !seeDetail ? (seeDetail = true) : (seeDetail = false);
  };
</script>

<div class="col s12 div__date ">
  <DatesCustom
    bind:date={dataRequest.executionDate}
    bind:colorLetterDates={managementColors.general.colorLetterDates}
    bind:defaultStyles
  /> &nbsp;
  <span
    class="request__id"
    use:styling={{ color: managementColors.general.colorLetterNumId }}
    >#{dataRequest.idReference}</span
  >
</div>

<div class="col s12 div__info__service">
  <div
    use:styling={{
      background: managementColors.general.bacgroundCardIcon,
    }}
    class="cont__header-icon--service"
  >
    <img
      class={filterSvg ? managementColors.title : "header-icon--completed"}
      alt="img"
      src={dataRequest.outletPhoto}
    />
  </div>

  <span
    class="header_nameproperty"
    use:styling={{ color: managementColors.general.colorLetter }}
  >
    {dataRequest.outletName}
  </span>
</div>

<div class="order-request__title col s12 ">
  <div class="order-request__title__left col s6 ">
    <span
      class="order-request__title-text {$locale}"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {$_("See order detail")}
    </span>
  </div>
  <div class="order-request__title__right col s6 ">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="more__tail "
      use:styling={{
        background: managementColors.general.backgroundButtonCircle,
      }}
      on:click={handleSeeDetail}
    >
      {#if !seeDetail}
        <i
          class="material-icons"
          use:styling={{
            color: managementColors.general.colorLetter,
          }}
        >
          keyboard_arrow_down
        </i>
      {:else}
        <i
          class="material-icons"
          use:styling={{
            color: managementColors.general.colorLetter,
          }}
        >
          keyboard_arrow_up
        </i>
      {/if}
    </div>
  </div>
</div>
{#if seeDetail}
  <div class="order">
    {#each dataRequest.menus as menu, i}
      <div
        class={menu.name ? "b__bottom__border" : "border__not"}
        use:styling={{
          borderBottom:
            "4px solid " + managementColors.general.bacgroundCardStatusBar,
        }}
      >
        <div class="col s12 cont__restaurant">
          <span
            class="restaurant__title"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {menu.name}
          </span>
        </div>

        {#each menu.items as item, i2}
          <div
            class={menu.items.length > 1 && menu.items.length > i2 + 1
              ? "border__item cont__order"
              : "cont__order"}
            use:styling={{
              color: managementColors.general.bacgroundCardStatusBar,
              borderBottomColor:
                managementColors.general.bacgroundCardStatusBar,
            }}
          >
            <div class="col s12 cont__item">
              <div class="col s12 cont__item__left">
                {#if item.photo}
                  <img class="item__img" alt="img" src={item.photo} />
                {/if}
                <span
                  class="amount__item"
                  use:styling={{
                    background:
                      managementColors.general.backgroundColorChipState,
                    color:
                      managementColors.general.backgroundIconStatusBarValidate,
                  }}>{item.cant}</span
                >
                <span
                  class="order__name"
                  use:styling={{ color: managementColors.general.colorLetter }}
                >
                  {item.name}
                </span>
              </div>
              <div class="col s12 cont__item__right">
                {#if item.price != ""}
                  <span
                    class="order__price"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {menu.typeMoney}
                    {item.price}
                  </span>
                {/if}
              </div>
            </div>

            <div class="list_modifiers">
              {#each item.modifiers as modifier, i}
                <span
                  class="modifier__name"
                  use:styling={{
                    color: managementColors.general.colorLetterNumId,
                  }}
                >
                  {modifier.name}
                </span>
                {#each modifier.options as option, i}
                  <li
                    class={modifier.options.length - 1 == i
                      ? "list_options--padding"
                      : "list_options"}
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    <span
                      class="option__name"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {option.value}
                    </span>
                    {#if option.price != ""}
                      <span
                        class="option__price "
                        use:styling={{
                          color: managementColors.general.colorLetter,
                        }}
                      >
                        {menu.typeMoney}
                        {option.price}
                      </span>
                    {/if}
                  </li>
                {/each}
              {/each}
              {#if item.note && item.note.toString().trim() != ""}
                <div class="col s12 cont__comment__title">
                  <span
                    class="comment__title {$locale}"
                    use:styling={{
                      color: managementColors.general.colorLetterNumId,
                    }}
                  >
                    {$_("Item notes")}
                  </span>
                </div>
                <div class="col s12 cont__comment__text">
                  <span
                    class="comment__text"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {item.note}
                  </span>
                </div>
              {/if}
            </div>
          </div>
        {/each}
      </div>

      {#if menu.note && menu.note.toString().trim() != ""}
        <div class="col s12 cont__comment__order">
          <span
            class="comment__title {$locale}"
            use:styling={{ color: managementColors.general.colorLetterNumId }}
          >
            {$_("Order notes")}
          </span>
        </div>
        <div class="col s12 cont__comment__text__order">
          <span
            class="comment__text"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {menu.note}
          </span>
        </div>
      {/if}
    {/each}
  </div>
{/if}

<div
  class="order__subtotal col s12 "
  use:styling={{
    background: managementColors.general.bacgroundCardStatusBar,
  }}
>
  <div class="order__subtotal__left col s6 ">
    <span
      class="order__subtotal__text "
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      Subtotal
    </span>
  </div>
  <div class="order__subtotal__right col s6 ">
    <span
      class="order__subtotal__text "
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {dataRequest.typeMoney}
      {dataRequest.menus.reduce(
        (total, menu) =>
          parseFloat(parseFloat(total) + parseFloat(menu.price)).toFixed(2),
        parseFloat(0.0).toFixed(2)
      )}
    </span>
  </div>
  <div class="order__subtotal__tax col s12 ">
    <span
      class="order__subtotal__tax__text {$locale}"
      use:styling={{ color: managementColors.general.colorletterButtonLInks }}
    >
      {$_("Additional charges will apply to the total")}
    </span>
  </div>
</div>

<style>
  .cont__order {
    padding: 0px 16px 8px 16px !important;
    margin: 0px !important;
    width: 100%;
    display: inline-block;
  }

  .order__name {
    font-size: 0.875rem;
    letter-spacing: 0px;
    font-weight: 600;
    opacity: 1;
  }

  .order__price {
    font-size: 0.875rem;
    letter-spacing: 0px;
    font-weight: 600;
    text-transform: uppercase;
    float: right;
    opacity: 1;
  }

  .modifier__name {
    font-size: 0.625rem;
    letter-spacing: 0px;
    font-weight: 500;
    text-transform: uppercase;
    word-break: break-word;
    opacity: 1;
  }

  .list_options {
    padding-bottom: 6px;
    padding-top: 7px;
  }

  .list_modifiers {
    padding-top: 17px;
  }

  .option__name {
    font-size: 0.875rem;
    letter-spacing: 0px;
    width: 70%;
    word-break: break-word;
    opacity: 1;
  }

  .option__price {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0px;
    opacity: 1;
    float: right;
    width: 30%;
    text-align: right;
  }

  .cont__comment__title {
    padding: 0px !important;
  }

  .cont__comment__text {
    padding: 0px 0px 32px 0px !important;
  }

  .cont__comment__order {
    padding: 0px 16px 0px 16px !important;
  }

  .cont__comment__text__order {
    padding: 0px 16px 32px 16px !important;
  }

  .comment__title {
    font-size: 0.75rem;
    letter-spacing: 0px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 1;
  }

  .comment__text {
    font-size: 1rem;
    letter-spacing: 0px;
    color: #686c6d;
    opacity: 1;
  }

  .cont__restaurant {
    padding: 0px 16px 16px 16px !important;
  }

  .restaurant__title {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .item__img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    object-position: center center;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    margin-right: 8px !important;
    opacity: 1;
  }

  .cont__item {
    display: flex;
    align-items: center;
    padding: 0px 0px 16px 0px !important;
  }

  .cont__item__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px !important;
  }

  .cont__item__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px !important;
  }

  .order-request__title {
    display: flex;
    align-items: center;
    padding: 0px 16px 19px 16px !important;
  }

  .order-request__title__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0px 0px 0px !important;
  }

  .order-request__title__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 0px 0px 0px !important;
  }

  .order-request__title-text {
    font-size: 0.875rem;
    color: #363636;
    letter-spacing: 0px;
    opacity: 1;
  }

  .more__tail {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    opacity: 1;
    cursor: pointer;
  }

  .order__subtotal {
    height: 76px;
    background: #f4f4f4;
    border-radius: 0px 0px 4px 4px;
    padding: 16px !important;
    opacity: 1;
  }

  .order__subtotal__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px !important;
  }

  .order__subtotal__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px !important;
  }

  .order__subtotal__tax {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px 0px 0px !important;
  }

  .order__subtotal__text {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .order__subtotal__tax__text {
    font-size: 0.75rem;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }

  .div__info__service {
    display: flex;
    align-items: center;
    padding: 0px 16px 19px 16px !important;
  }

  .cont__header-icon--service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    opacity: 1;
  }

  .header-icon--completed {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
  }

  .header_nameproperty {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .div__date {
    padding: 16px 16px 4px 16px !important;
  }

  .list_options--padding {
    display: flex;
    padding-top: 7px;
    padding-bottom: 24px !important;
  }

  .request__id {
    font-size: 0.875rem;
    font-weight: 700;
  }

  @media only screen and (min-width: 600px) {
    .header_nameproperty {
      font-size: 1.125rem;
    }

    .order__subtotal__tax__text {
      font-size: 16px;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 992px) {
    .header_nameproperty {
      font-size: 1.5rem;
    }

    .order__name {
      font-size: 1rem;
    }

    .order__price {
      font-size: 1rem;
    }

    .modifier__name {
      font-size: 0.75rem;
    }

    .option__name {
      font-size: 1rem;
    }

    .option__price {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    .restaurant__title {
      font-size: 1rem;
    }

    .order-request__title-text {
      font-size: 1rem;
    }

    .order__subtotal__text {
      font-size: 1.125rem;
    }

    .order__subtotal__tax__text {
      font-size: 1rem;
    }
  }

  .amount__item {
    background: #e3e3e3;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    text-align: center;
    margin-right: 5px;
    margin-left: -5px;
    font-weight: 700;
  }

  .b__bottom__border {
    border-bottom: 4px solid #e3e3e3;
    margin-bottom: 22px;
  }

  .border__not {
    display: none;
  }

  .border__item {
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 18px !important;
  }
</style>
